import React from 'react';

import BaseIcon from '../../utils/BaseIcon';

const LifeInsuranceGraphicIcon = props => {
  const {size = 48, ...otherProps} = props;
  const iconFill = 'currentColor';

  return (
    <BaseIcon size={size} {...otherProps} data-mesh-component= 'LIFE-INSURANCE-GRAPHIC-ICON'>
      <g fill={iconFill} fillRule="evenodd"><path d="M12.73 1.26l-.72.699-.717-.698a4.428 4.428 0 00-6.98 1.098l-.101.21a4.185 4.185 0 00.765 4.56l5.586 5.666a2.033 2.033 0 002.895 0L18.9 7.28a4.175 4.175 0 00.813-4.914l-.125-.22a4.427 4.427 0 00-6.858-.887zm3.608-.2c.993.156 1.866.74 2.39 1.596l.106.187a3.172 3.172 0 01-.497 3.583l-5.59 5.667a1.033 1.033 0 01-1.472 0L5.833 6.574a3.184 3.184 0 01-.713-3.587l.085-.177a3.427 3.427 0 015.392-.83l1.065 1.034a.5.5 0 00.697 0l1.067-1.036a3.426 3.426 0 012.912-.918z"/><path fillRule="nonzero" d="M2.045 10.89c1.072 0 1.95.829 2.028 1.881l.005.152v2.272l.01-.005a1.931 1.931 0 012.471.539l1.26 1.888a5.1 5.1 0 01.851 2.558l.008.274v3.067a.5.5 0 01-.992.09l-.008-.09v-3.067a4.1 4.1 0 00-.548-2.048l-.142-.23-1.18-1.766a.93.93 0 00-1.313-.293.934.934 0 00-.375 1.054l.04.099 1.382 2.3a.5.5 0 01-.804.587l-.053-.073-1.401-2.334a1.924 1.924 0 01-.2-.927l-.006-.062v-3.833a1.033 1.033 0 00-2.06-.113l-.006.113v5.286c0 .853.266 1.683.752 2.367l.138.183 2.058 2.434a.5.5 0 01-.7.709l-.063-.063-2.068-2.445a5.1 5.1 0 01-1.11-2.919l-.007-.266v-5.286c0-1.123.91-2.033 2.033-2.033zm19.93 0c1.073 0 1.95.829 2.028 1.881l.006.152v5.286a5.102 5.102 0 01-.963 2.981l-.163.215-2.058 2.434a.5.5 0 01-.816-.572l.052-.074 2.05-2.423a4.1 4.1 0 00.891-2.329l.007-.232v-5.286a1.033 1.033 0 00-2.06-.113l-.006.113v3.833l-.007.075c.006.264-.041.534-.147.794l-.069.153-1.382 2.3a.5.5 0 01-.896-.435l.04-.08 1.362-2.266a.93.93 0 00-1.566-.988l-1.245 1.863a4.1 4.1 0 00-.68 2.008l-.01.27v3.066a.5.5 0 01-.992.09l-.008-.09V20.45a5.1 5.1 0 01.712-2.601l.146-.232 1.155-1.73a1.93 1.93 0 012.586-.698v-2.266c0-1.123.911-2.033 2.034-2.033z"/></g>
    </BaseIcon>
  );
};

LifeInsuranceGraphicIcon.displayName = 'LifeInsuranceGraphicIcon';

LifeInsuranceGraphicIcon.displayName = 'LifeInsuranceGraphicIcon';
LifeInsuranceGraphicIcon.iconName = 'LifeInsurance';

export default LifeInsuranceGraphicIcon;
